<template>
  <label :class="{ [$style.radio]: true, [$style.disabled]: disabled }">
    <input
      :checked="isChecked(value)"
      type="radio"
      :name="name"
      :value="value"
      :disabled="disabled"
      :class="{ [$style.disabled]: disabled }"
      @change="$emit('change', value)"
    />
    <slot />
  </label>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    value: Object | String
    name: string
    disabled?: boolean
    checked: String | Number | Object | Boolean
    comparisonProperty?: string
  }>(),
  {
    disabled: false,
    comparisonProperty: "",
  },
)

defineEmits<{
  (e: "change", v: any): void
}>()

const typeGuard = (item: unknown, key: string): item is Record<string, unknown> => {
  return !!(item && typeof item === "object" && key in item)
}

const isChecked = (value: unknown): boolean => {
  // 既存で comparisonProperty を利用する箇所があり、残している
  const comparisonProperty = props.comparisonProperty
  if (comparisonProperty && typeGuard(value, comparisonProperty) && typeGuard(props.checked, comparisonProperty)) {
    return value[comparisonProperty] === props.checked[comparisonProperty]
  } else {
    return value === props.checked
  }
}
</script>

<style scoped module lang="scss">
@mixin check-circle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  content: "";
}

.radio {
  display: flex;
  align-items: center;
  cursor: pointer;

  input[type="radio"] {
    // デフォルトのスタイルを消す
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    // radio のスタイルが崩れないように
    min-width: 1rem;
    min-height: 1rem;

    padding: 0;
    margin-right: 0.5rem;
    cursor: pointer;
    position: relative;
    border: 1px solid;
    border-radius: 100%;
    border-color: $primary-20;
    bottom: 1px;

    &:checked {
      border-color: $primary;
      background-color: $white;
    }

    // ラジオボタン内のドットを生成
    &:checked:before {
      @include check-circle;
      border-color: $white;
      background: $primary;
    }

    &:disabled {
      cursor: not-allowed;
      border-color: $black-20;

      &:checked {
        border-color: $black-20;
      }

      // ラジオボタン内のドットを生成
      &:checked:before {
        @include check-circle;
        border-color: $black-20;
        background: $white;
      }
    }
  }

  &.disabled {
    cursor: not-allowed;
    color: $black-20;
  }
}
</style>
